<template>
    <div class="">
    <div class="card bg-nineth w-35r">
        <div class="text-center">
            <p class="text-2">Sorry! We could not find what you were looking for.<br>
                But maybe we could look into it.</p>
            <div>
                <validated-input placeholder="Disease Name" class="text-2" :rules="{required:true}" v-model="model.disease"></validated-input>
            </div>
            <p class="text-2">Please verify the keyword above.<br>
                You may add your own notes below and Request assistance from our<br>
                admin panel by clicking on submit button below</p>
            <div>
                <validated-input placeholder="Add Your Notes" v-model="model.notes"></validated-input>
            </div>
            <btn text="SUBMIT" @click="addItem" :loading="loading" loading-text="Saving...s" color="seventh" class="px-5" :border-radius="1"></btn>
        </div>
    </div></div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'DiseaseModal',
    data () {
        return {
            loading: false,
            addUrl: urls.userHome.disease.requestDisease,
            model: {
                disease: '',
                notes: ''
            }
        };
    },
    methods: {
        async addItem () {
            try {
                this.loading = true;
                this.response = await axios.form(this.addUrl, this.model);
                const result = this.response.data;
                console.log('result', result);
                if (result.status === true) {
                    this.$notify('Request Added Successfully', 'Success', { type: 'success' });
                    this.loading = false;
                    this.$router.push('/animal-page/');
                } else {
                    const errors = this.response.data.errors;

                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}:  ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.this.response.status + ' ' + error.this.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>

<style scoped>

</style>
