<template>
    <div>
        <div class="fl-x-cc text-7"></div>
        <div class="row">
            <div class="col-lg-12 mt-4 p-0">
                <p class="text-4">Our predictions Here.,</p>
                <validated-input placeholder="search" type="text" :border-radius="1" v-model="search"></validated-input>
            </div>
            <template v-if="items && items.length > 0">
                <div class="col-lg-12 fl-x-cc b-1 mt-2" v-for="(item, index) in items" :key="index">
                    <p class="m-0 text-2 font-weight-500 p-3" @click="$router.push('/disease-list/'+item.id+'/')">
                        {{ item.disease }}
                    </p>
                </div>
            </template>
            <!--            <div class="fl-x fl-j-c " v-else>-->
            <!--                <disease-modal v-if="startSearch"></disease-modal>-->
            <!--                <div v-else>-->
            <!--                    <found-item v-if="loading"></found-item>-->

            <!--                    <div class="fl-x" v-else>-->
            <!--                        <loading-animation></loading-animation>-->
            <!--                        <p>Loading...</p>-->
            <!--                    </div>-->
            <!--                </div>-->

            <!--            </div>-->
            <div class="fl-x fl-j-c" v-else>
                <disease-modal v-if="startSearch" class="ml-9"></disease-modal>
                <div v-else>
                    <found-item v-if="loading"></found-item>
                    <div class="fl-x" v-else>
                        <loading-animation></loading-animation>
                        <p>Loading...</p>
                    </div>
                </div>
            </div>
    </div>

    </div>
</template>
<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import DiseaseModal from '@/views/user-home/disease/DiseaseModal';
import FoundItem from '@/views/user-home/disease/FoundItem';

export default {
    name: 'DiagonoseFindings',
    components: { FoundItem, DiseaseModal },
    props: {
        items: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            loading: false,
            startSearch: false,
            data: [],
            listURL: urls.userHome.userHomeDiagnose.diagnose,
            search: '',
            cat_id: this.$route.params.id
        };
    },
    watch: {
        search (newVal, oldVal) {
            console.log(newVal, oldVal);
            this.searchItem();
        }
    },
    mounted () {
        this.fetchItem();
    },
    methods: {
        async searchItem () {
            console.log('search item');
            this.startSearch = true;
            console.log('Searching...', this.startSearch);
            if (this.search === undefined) {
                this.search = '';
            }
            try {
                this.loading = true;
                const response = await axios.form(this.listURL, { search: this.search, species: this.$route.params.id });
                const result = response.data.data;
                this.items = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}: ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        },
        async fetchItem () {
            console.log('Fetching...');
            this.loading = true;
            try {
                const response = await axios.form(this.listURL, { cat_id: this.$route.params.id });
                const result = response.data.data;
                this.data = result;
                console.log('result', result);
                if (result.success === true) {
                    this.loading = false;
                } else {
                    const errors = response.data.errors;
                    for (const key in errors) {
                        if (Object.prototype.hasOwnProperty.call(errors, key)) {
                            const errorMessage = `${key}: ${errors[key]}`;
                            this.$notify(errorMessage, 'Warning', { type: 'warning' });
                        }
                    }
                    this.loading = false;
                }
            } catch (error) {
                this.$notify(
                    'Something Went Wrong..!!',
                    error.response.status + ' ' + error.response.statusText,
                    { type: 'danger' }
                );
                this.loading = false;
            }
        }
    }
};
</script>
<style scoped>

</style>
